import './Footer.scss';
import logo_green from './../../../assets/img/IOTC Ventures Logo.png';
import twitter_logo from './../../../assets/img/twitter.png';
import linkedin_logo from './../../../assets/img/linkedin.png';
import instagram_logo from './../../../assets/img/instagram.png';


function Footer() {
  return (
    <div className='footer'>
      <div className="footer-row row">
        <div className="col-12 col-md-5">
          <img src={logo_green} className="logo-green" alt="logo" />
        </div>
        <div className="col-12 col-md-7">
          <div className="row">
            <div className="col-12 col-md-4">
              <h3 className="footer-title">
                  GET IN TOUCH
              </h3>
              <h4 className="footer-text">
                  info@iotc-ventures.com
              </h4>
            </div>
            <div className="col-12 col-md-4">
              <h3 className="footer-title">
                  CONNECT
              </h3>
              <a  rel="noreferrer" href="/" target="_blank"><img src={twitter_logo} className="social-logo" alt="twitter" /></a>
              <a  rel="noreferrer" href="https://www.linkedin.com/company/iotc-ventures/?originalSubdomain=uk" target="_blank"><img src={linkedin_logo} className="social-logo" alt="linkedin" /></a>
              <a  rel="noreferrer" href="/" target="_blank"><img src={instagram_logo} className="social-logo" alt="instagram" /></a>
            </div>
            <div className="col-12 col-md-4">
              <h3 className="footer-title">
                  IOTC VENTURES
              </h3>
              <h4 className="footer-text">
                  No.28, Battaramulla Road,
                  <br></br>
                  Kotte, Sri Lanka 
                  <br></br>
                  <br></br>
                  +94 117 555 666
                  <br></br>
                  info@iotc-ventures.com
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className='copyrights'>
        <h4 className="footer-text">
          © Copyrights 2024 IOTC Ventures
        </h4>
      </div>
    </div>

  );
};

export default Footer;
