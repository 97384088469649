// import Home from './components/home/Home';
import './Home.scss';
import logo from "./../../../assets/img/IOTC Ventures Logo white.png"
import Indexanimation from './../../modules/indexanimation/Indexanimation'
import Footer from './../../modules/footer/Footer'
import { Fade } from "react-awesome-reveal";

function Home() {
  return (
    <div className="home-page">
        <div className="content">
            <div className="home row align-items-center">
              <div className="col-12 col-md-6">
                <Fade delay={0} triggerOnce>
                    <img src={logo} className="App-logo" alt="logo" />
                </Fade>
                <Fade delay={600} triggerOnce>
                    <h1 className="title">
                        Creating a 
                        <br></br>
                        <span>Sustainable future</span> 
                        <br></br>
                        For All
                    </h1>
                </Fade>
                <Fade delay={900} triggerOnce>
                    <div className="line"></div>
                </Fade>
                <Fade delay={1500} triggerOnce>
                    <h2 className="text">
                        Coming Soon ! 
                    </h2>
                </Fade>
              </div>
              <div className="indexanime col-12 col-md-6">
                <Fade delay={300} triggerOnce>  
                    <Indexanimation></Indexanimation>
                </Fade>
              </div>
            
            </div>
        </div>
        <Footer/>
    </div>
    
    
  );
}

export default Home;
