import './Indexanimation.scss';
import outlinewheel from './../../../assets/img/Outline Wheel.png';
import transparentwheel from './../../../assets/img/Transperant Wheel.png';
import bg from './../../../assets/img/bg.png';

const Indexanimation = () => {
  return (
    <div>
        {/* <img src={outlinewheel} className="outlinewheel" alt="outlinewheel" />
        <img src={transparentwheel} className="transparentwheel" alt="transparentwheel" /> */}
        <img src={bg} className="bg" alt="bg" />
    </div>
  );
};

export default Indexanimation;
